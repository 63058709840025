
import Echo from 'laravel-echo';
import { useEffect, useState } from 'react';
import {postMessage} from '../commponents/Services/index'
import "./Chat.css"
window.io = require('socket.io-client')   

function Chat() {
  const [isConnected, setIsConnected] = useState(false);
  const [laravelecho, setLaravelecho] = useState(false);
  const [messages,setMessages] = useState([]);
  const [socketUserID,setSocketUserID] = useState('');
  const [messageValue,setMessageValue] = useState('');

  useEffect(()=>{
    let socket = null;

    const initializeSocket = () => {
      // Replace 'http://jgago.com:6001' with your Socket.IO server URL
      socket = new Echo({
        broadcaster: 'socket.io',
        host: 'http://jgago.com:6001'
      });
      setTimeout(()=>{
        setSocketUserID(socket.socketId())
      },1000)
      
      socket.channel('receive-notification').listen('SendNotificationEvent', (data) => {
        setMessages((prevMessages) => [...prevMessages, data]);
        console.log('data',data );
      });
      

      setIsConnected(socket.connector.socketId() !== null);
    };
    initializeSocket();

    // Clean up the socket connection on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  },[]); 
  
  
  

  const sendMessages = async () => {
    const result = await postMessage(messageValue,socketUserID);
    if(result.status == 200){
      setMessageValue('')
    }
   console.log('ye console hai',)
 };



  return (
    <div className="App">
      <div className="container mt-5"> 
        <div className="row">
          <div className="col-md-8 mx-auto">
              
              <div className="card shadow-lg rounded">
                <div className="card-body px-0 pt-0">
                    <h5 className="card-title p-2 mt-0 text-white bg-secondary">
                      Chat
                    </h5>
                    <div className="chat-box px-3">
                      <div className="d-flex flex-column">
                          {messages.map((message, index) => (
                           <>
                            <div
                                key={index}
                                className="p-2 my-2 message first-user d-inline-block"
                            >
                                {message.notification.message}
                            </div>
                               </>
                          ))}
                   
                      </div>
    
                      <div className="  d-flex  justify-content-end">
                          <div className="d-inline-block">
                            <span className="p-2 d-block my-3 message  second-user">
                                User 2: Hi there!
                            </span>
                            
                          </div>
                      </div>
                    </div>
                </div>
              </div>
    
              
              <div className="input-group mt-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type your message..."
                    value={messageValue}
                    onChange={e=>{setMessageValue(e.target.value)}}
                />
                <button className="btn btn-primary" onClick={()=>sendMessages()}>Send</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
