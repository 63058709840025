import axios from "axios";

const api = axios.create({
   withCredentials: true, // Enable sending cookies in cross-origin requests
});

export const registerUser = async (name, Email, Password) => {
   console.log(name, Email, Password);
   try {
      // Fetch CSRF token from the server
      await api.get("https://chat.jgago.com/sanctum/csrf-cookie");

      // Send the AJAX request with the CSRF token in the headers
      const response = await api.post(
         "https://chat.jgago.com/api/auth/register",
         {
            email: Email,
            password: Password,
            name: name,
         },
         {
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
            },
         }
      );
      console.log("response", response);
      return await response.data;
   } catch (e) {
      throw e;
   }
};
export const loginUser = async (name, Email, Password) => {
   console.log(name, Email, Password);
   try {
      // Fetch CSRF token from the server
      await api.get("https://chat.jgago.com/sanctum/csrf-cookie");

      // Send the AJAX request with the CSRF token in the headers
      const response = await api.post(
         "https://chat.jgago.com/api/auth/login",
         {
            email: Email,
            password: Password,
            name: name,
         },
         {
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
            },
         }
      );
      console.log("response", response);
      return await response.data;
   } catch (e) {
      throw e;
   }
};


export const postMessage  = async (message,user) => {
   console.log('message',message);
   try {
      // Fetch CSRF token from the server
      await api.get("https://chat.jgago.com/sanctum/csrf-cookie");

      // Send the AJAX request with the CSRF token in the headers
      const response = await api.post(
         "https://chat.jgago.com/api/message",
         {
            message: message,
            user:user

         },
         {
            headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
            },
         }
      );
      console.log("response", response);
      return await response.data;
   } catch (e) {
      throw e;
   }
};