import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../Services";

const Login = () => {
   let Navigate = useNavigate();
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   // Navigate("/chat");
   const SignIn = () => {
      const result = loginUser(name, email, password);
      if (result?.status === true) {
         localStorage.setItem("token", result?.token);
         Navigate("/chat");
      }
   };
   return (
      <div className="container pt-5">
         <form
            className="row card shadow-md px-4 pb-4 g-3 w-50 mx-auto "
            style={{ backgroundColor: "#e5e5e530" }}
         >
            <h4 className="pt-3">Sign In</h4>

            <div className="row gy-5">
               <div className="col-12 mt-2">
                  <label htmlFor="userName" className="form-label">
                     Name
                  </label>
                  <input
                     type="text"
                     className="form-control"
                     id="userName"
                     value={name}
                     onChange={(e) => setName(e.target.value)}
                  />
               </div>
               <div className="col-12 mt-2">
                  <label htmlFor="inputEmail4" className="form-label">
                     Email
                  </label>
                  <input
                     type="email"
                     className="form-control"
                     id="inputEmail4"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                  />
               </div>
               <div className="col-12 mt-2">
                  <label htmlFor="inputPassword4" className="form-label">
                     Password
                  </label>
                  <input
                     type="password"
                     className="form-control"
                     id="inputPassword4"
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                  />
               </div>

               <div className="col-2 mt-3">
                  <button
                     type="submit"
                     className="btn btn-primary"
                     onClick={() => SignIn()}
                  >
                     Sign in
                  </button>
               </div>
            </div>
         </form>
      </div>
   );
};

export default Login;
