// import React, { useRef, useEffect } from "react";
// import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ImageHighlighter from "./ImageHighlighter";
import Chat from "./commponents/Chat";
import Login from "./commponents/Auth/Login";
import Register from "./commponents/Auth/Register";

const App = () => {
   return (
      // <div>
      //    <ImageHighlighter />
      // </div>
      <BrowserRouter>
         <Routes>
            <Route path="/" element={<Register />} />
            <Route path="login" element={<Login />} />
            <Route path="chat" element={<Chat />} />
            <Route path="svg" element={<ImageHighlighter />} />
         </Routes>
      </BrowserRouter>
   );
};

export default App;
